import React from 'react';
// import ReactDOM from 'react-dom';
import { Component } from 'react';
import { connect } from 'react-redux';
import dynamic from 'next/dynamic';
const MediaQuery = dynamic(()=> import('react-responsive'), { ssr: false });
import CreateBoardFromMyFavPopup from './createBoardFromMyFavPopup';
import CreateBoardPopup from './createBoardPopup';
import { addOrRemoveFavoriteBoardItem, addRemoveFavoriteItem } from './productFavFunctions'

class BoardsListTooltip extends Component {

    constructor(props) {
        super(props);
        this.state = {
            createBoardModal: false,
            createBoardFavModal: false,
            boards: []
        }
        this.allBoards = React.createRef();
    }

    componentDidMount() {
        this.setState({ boards: this.props.boards })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.boards != this.props.boards) {
            this.setState({ boards: this.props.boards })
        }
    }

    toggleCreateBoardModal = async () => {
        if(this.props.comp == 'fromBoard'){
            await this.setState({
                createBoardFavModal: !this.state.createBoardFavModal
            })    
            return
        }
        await this.setState({
            createBoardModal: !this.state.createBoardModal
        })
        if(this.props.refreshFavData && !this.state.createBoardModal){
            this.props.refreshFavData()
        }
    }

    toggleCreateBoardFavModal = async () => {
        await this.setState({
            createBoardFavModal: !this.state.createBoardFavModal
        })
        if(!this.state.createBoardFavModal) this.props.refreshBoardData('insideBoard')
    }

    selectBoard = async (event, product_id, board_id, item_status) => {
        let selector = event.target.classList;
        let id = 'product_' + product_id;
        if(this.props.comp == 'mob') {
            id = 'product_' + product_id + '_mob'
        }
        let fav_status = 1;
        if(document.getElementById(id).classList.toString().indexOf('far') > -1)
            fav_status = 0
        if (selector.toString().indexOf('selected') > -1) {
            selector.remove('selected');
            if(this.props.fromSaveLater == true){
                this.props._updateProdAfterToggleFavBoard(product_id, board_id, 0)
            }
            await this.props._addOrRemoveFavoriteBoardItem(product_id, board_id, false, fav_status,true)
        } else {
            selector.add('selected');
            await event.target.parentElement.parentElement.parentElement.firstChild.classList.remove('far');
            await event.target.parentElement.parentElement.parentElement.firstChild.classList.add('fas');
            // console.log('hearted -------------------------->>>>>>>>>>>>>>>>>')
            if(this.props.fromSaveLater == true){
                this.props._updateProdAfterToggleFavBoard(product_id, board_id, 1)
            }
            await this.props._addOrRemoveFavoriteBoardItem(product_id, board_id, true, fav_status,true)
        }
    }

    toggleFavorite = (event) => {
        let favBox = event.currentTarget.parentElement.parentElement
        if(favBox)
        favBox.classList.remove('filled')
        let heartIcon = favBox.children[0]
        let selectedBoards;
        if(this.allBoards.current != null){
            selectedBoards = [].slice.call(this.allBoards.current.children)
            selectedBoards.filter(boards => {
                if(boards.classList){
                    boards.classList.remove('selected')
                }
            })
        }
        let boardsList = document.querySelectorAll('.newBoard-tooltip')
        boardsList.forEach((val, ind) => {
            if(boardsList[ind]) {
                boardsList[ind].style.display = "none"
            } 
        })
        heartIcon.classList.remove('fas')
        heartIcon.classList.add('far')
        let productId = heartIcon.getAttribute('id')
        productId = parseInt(productId.split("_")[1])
        this.props._addRemoveFavoriteItem(productId, 0, true)
        
    }

    render() {
        let boards = this.state.boards
        if(boards.length > 0) {
            boards.find(boardlist => {
                boardlist.selected = false
            })
        }
        if (this.props.favoriteBoards != null) {
            this.props.favoriteBoards.filter(board => {
                boards.find(boardlist => {
                    if (board.board_id == boardlist.board_id) { boardlist.selected = true }
                })
            })
        }
        let product_id = 0
        if(this.props.comp == 'products')
        product_id = this.props.item.id
        else
        product_id = this.props.item.id

        if(product_id == undefined)
        product_id = this.props.item.product_id
        return (
            <div className={`newBoard-tooltip boardList_`+product_id + (this.props.boardUniqueId != undefined ? (' '+this.props.boardUniqueId+'_'+product_id): '')}  id={'board_'+product_id}>
                <MediaQuery maxWidth={767}>
                    <p><span onClick={this.toggleCreateBoardModal}>Create a Board</span></p>
                    <p onClick={this.toggleFavorite.bind(this)}><span>Remove Favorite</span></p>
                </MediaQuery>
                {boards.length > 0 &&
                    <ul ref={this.allBoards}>
                        {boards.map((board, index) => {
                            if (this.props.comp == 'products') return (<li key={index} id={'boardItem-'+product_id+'-'+board.board_id} className={board.selected ? 'selected boardItem'+board.board_id : 'boardItem'+board.board_id} onClick={(e) => this.selectBoard(e, product_id, board.board_id, this.props.item.is_favorite)}>{board.board_name}</li>)
                            if (this.props.comp == 'mob') return (<li key={index} className={board.selected ? 'selected boardItem'+board.board_id : 'boardItem'+board.board_id} id={'boardItem-'+product_id+'-'+board.board_id} onClick={(e) => this.selectBoard(e, product_id, board.board_id, this.props.item.is_favorite)}>{board.board_name}</li>)
                            else return (<li key={index} className={board.selected ? 'selected board'+board.board_id+' boardItem'+index+product_id : 'board'+board.board_id+' boardItem'+index+product_id} id={'boardItem-'+product_id+'-'+board.board_id} onClick={(e) => this.selectBoard(e, product_id, board.board_id, this.props.item.is_favorite)}>{board.board_name}</li>)
                        })}
                    </ul>
                }
                <MediaQuery minWidth={768}>
                    <p><span onClick={this.toggleCreateBoardModal}>Create a Board</span></p>
                </MediaQuery>
            
                <CreateBoardPopup isOpen={this.state.createBoardModal} fromCollection={product_id} isFavorite={this.props.item.is_favorite} toggle={this.toggleCreateBoardModal} />
                {this.props.comp == 'fromBoard' &&
                    <CreateBoardFromMyFavPopup isOpen={this.state.createBoardFavModal} fromCollection={product_id} isFavorite={this.props.item.is_favorite} toggle={this.toggleCreateBoardFavModal} addBoard={this.props.addBoard} customerBoards={this.props.boards} />
                }
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        _addRemoveFavoriteItem: (product_id, item_status, refreshData) => dispatch(addRemoveFavoriteItem(product_id, item_status, refreshData)),
        _addOrRemoveFavoriteBoardItem: (product_id, board_id, add_to_board, item_status,refresh) => dispatch(addOrRemoveFavoriteBoardItem(product_id, board_id, add_to_board, item_status,refresh))
    }
}

export default connect(null, mapDispatchToProps)(BoardsListTooltip);