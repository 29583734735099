import React from 'react';
// import ReactDOM from 'react-dom';
import Gconfig from "globalconfig";
import { getAuthTokenfromcookie } from '@/functions/auth-token';
import { fetchProductDataSuccess } from "redux/actions/collectionPageActions"
import { fetchCustomerBoardsSuccess,fetchCustomerBoardsFailure } from "redux/actions/customerBoardsActions"
import { fetchproductSuccess } from "redux/actions/productActions";
import { fetchCartSuccess } from "redux/actions/cartActions";
import jsCookie from 'js-cookie'

let addRemoveFavoriteItem = (product_id, item_status, refreshData) => {
    let customer_token = getAuthTokenfromcookie();
    let customer_ip = jsCookie.get('customer_ip')
    let request_guid = jsCookie.get('request_guid')
    return async (dispatch, getState) => {
        return fetch(Gconfig.fpAPI + 'Customer/AddOrRemoveFavoriteItem', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + customer_token,'X-Request-For': customer_ip, 'guid': request_guid },
            body: JSON.stringify({ product_id: product_id, item_status: item_status })
        }).then(response => response.json()).then(data => {
            let casheCollectionkey = jsCookie.get('casheCollectionkey')
            localStorage.setItem('fav_change', casheCollectionkey)
            if (refreshData) {
                let priviousData = getState().collectionPage;
                if(Array.isArray( priviousData.productData.data)){
                    priviousData.productData.data.find(product => {
                        if (product.id == product_id) {
                            if (item_status == 1)
                                product.is_favorite = true
                            else
                                product.is_favorite = false
                                product.favorite_boards = null
                        }
                    })
                    dispatch(fetchProductDataSuccess(priviousData.productData.data))
                }
            }
            if(Gconfig.environment == 'production' && location.host == 'www.fashionpass.com' && item_status == 1){
                if(gtag){
                    gtag({
                        event: "add_to_wishlist",
                        ecommerce: {
                          currency: "USD",
                          value: data.favorite_item.product.sizes && data.favorite_item.product.sizes.length >0 &&data.favorite_item.product.sizes[0].price,
                          items: [
                            {
                                item_id: data.favorite_item.product.product_id,
                                item_name: data.favorite_item.product.product_title,
                                currency: "USD",
                                item_brand: data.favorite_item.product.vendor,
                                price: data.favorite_item.product.sizes && data.favorite_item.product.sizes.length >0 &&data.favorite_item.product.sizes[0].price,
                                quantity: 1
                            }
                          ]
                        }
                    });
                }
            }
            return data
        })
    }
}
let addFavoriteBoard = (board_name, board_description,fromCollection,isfav) => {
    let customer_token = getAuthTokenfromcookie();
    let customer_ip = jsCookie.get('customer_ip')
    let request_guid = jsCookie.get('request_guid')
    return async (dispatch, getState) => {
        return fetch(Gconfig.fpAPI + 'Customer/AddFavoriteBoard', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + customer_token,'X-Request-For':customer_ip, 'guid': request_guid },
            body: JSON.stringify({ board_name: board_name, board_description: board_description })
        }).then(response => response.json()).then(data => {
            if(data.success){
                let currentBoards = getState().customerBoards.customerBoards
                let updatedBoards = []
                updatedBoards.push(data.favorite_board, ...currentBoards)
                console.log(updatedBoards, 'updated')
                dispatch(fetchCustomerBoardsSuccess(updatedBoards))
                if(fromCollection){
                    if(isfav)
                    isfav = 1
                    else
                    isfav = 0
                    dispatch(addOrRemoveFavoriteBoardItem(fromCollection, data.favorite_board.board_id, true, isfav,true))
                    setTimeout(() => {
                        if(document.getElementById('boardItem-'+fromCollection+'-'+data.favorite_board.board_id))
                        document.getElementById('boardItem-'+fromCollection+'-'+data.favorite_board.board_id).classList.add('selected')
                    }, 500)
                }
                let productHeart = document.querySelector('#product_' + fromCollection)
                if(productHeart){
                    productHeart.classList.remove('far');
                    productHeart.classList.add('fas');
                }
            }
            return data
        })
    }
}
let getFavoriteBoardsList = () => {
    let customer_token = getAuthTokenfromcookie();
    let customer_ip = jsCookie.get('customer_ip')
    let request_guid = jsCookie.get('request_guid')
    return async (dispatch, getState) => {
        if(customer_token != undefined && customer_token != 'not_login'){
            return fetch(Gconfig.fpAPI + 'Customer/GetFavoriteBoards', {
                method: 'GET',
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + customer_token,'X-Request-For': customer_ip, 'guid': request_guid }
            }).then(response => response.json()).then(data => {
                if(data.success){
                    data.favorite_boards
                    dispatch(fetchCustomerBoardsSuccess(data.favorite_boards))
                }else{
                    dispatch(fetchCustomerBoardsFailure())
                }
                return data
            })
        }else{
            dispatch(fetchCustomerBoardsFailure())
        }
    }
}
let addOrRemoveFavoriteBoardItem = (product_id, board_id, add_to_board, item_status,refreshData) => {
    let customer_token = getAuthTokenfromcookie();
    let customer_ip = jsCookie.get('customer_ip')
    let request_guid = jsCookie.get('request_guid')
    return async (dispatch, getState) => {
    return fetch(Gconfig.fpAPI + 'Customer/AddOrRemoveFavoriteBoardItem', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + customer_token,'X-Request-For':customer_ip, 'guid': request_guid },
        body: JSON.stringify({ product_id: product_id, board_id: board_id, add_to_board: add_to_board, item_status: item_status })
    }).then(response => response.json()).then(data => {
        if (refreshData) {
            getFavoriteBoardsByProduct(product_id).then(productData => {
                console.log(productData.favorite_boards)
                let priviousData = getState().collectionPage;
                let currentProductData = getState().product.product_detail
                let currentCartData = getState().cart
                if(currentCartData.cart != null && currentCartData.cart.length != 0){
                    if(currentCartData.cart.items.length){
                        currentCartData.cart.items.find((item,index) => {
                            if(item.sku.product.product_id == product_id){
                                currentCartData.cart.items[index].sku.product.favorite_boards = productData.favorite_boards
                                if (item_status == 0) currentCartData.cart.items[index].sku.product.is_favorite = true
                            }
                        })
                        dispatch(fetchCartSuccess(currentCartData.cart))
                    }
                }
                if(Object.keys(currentProductData).length !== 0){
                    let productInfo = currentProductData.product_info
                    if(productInfo.product_id == product_id){
                        productInfo.favorite_boards = productData.favorite_boards
                        if (item_status == 0) productInfo.is_favorite = true
                        dispatch(fetchproductSuccess(currentProductData))
                    }
                }
                if(Array.isArray(priviousData.productData.data)){
                    priviousData.productData.data.find(product => {
                        if (product.id == product_id) {
                            if (item_status == 0)
                                product.is_favorite = true
                            if(productData.success){
                                product.favorite_boards = productData.favorite_boards
                            }
                        }
                    })
                    dispatch(fetchProductDataSuccess(priviousData.productData.data))
                }
            })
          
        }
        return data
    })
    }
}
let getFavoriteItems = () => {
    let customer_token = getAuthTokenfromcookie();
    let customer_ip = jsCookie.get('customer_ip')
    let request_guid = jsCookie.get('request_guid')
    return fetch(Gconfig.fpAPI + 'Customer/GetFavoriteItems', {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + customer_token,'X-Request-For':customer_ip, 'guid': request_guid }
    }).then(response => response.json()).then(data => {
        return data
    })
}
let getFavoriteBoardItems = (board_id) => {
    let customer_token = getAuthTokenfromcookie();
    let customer_ip = jsCookie.get('customer_ip')
    let request_guid = jsCookie.get('request_guid')
    let notlogin = ''
    if(customer_token == undefined || customer_token == 'not_login')
    notlogin = '/false'
    return fetch(Gconfig.fpAPI + 'Customer/GetFavoriteBoardItems/' + board_id+notlogin, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + customer_token,'X-Request-For':customer_ip, 'guid': request_guid }
    }).then(response => response.json()).then(data => {
        return data
    })
}
let removeFavoriteBoard = (id) => {
    let customer_token = getAuthTokenfromcookie();
    let customer_ip = jsCookie.get('customer_ip')
    let request_guid = jsCookie.get('request_guid')
    return async (dispatch, getState) => {
        return fetch(Gconfig.fpAPI + 'Customer/RemoveFavoriteBoard/' + id, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + customer_token,'X-Request-For':customer_ip, 'guid': request_guid }
        }).then(response => response.json()).then(data => {
            let currentData = getState().customerBoards.customerBoards
            currentData = currentData.filter(board => {
                if(board.board_id != id){
                    return board
                }
            })
            dispatch(fetchCustomerBoardsSuccess(currentData))
            return data
        })
    }
}

let renameFavoriteBoard = (renameData) => {
    let customer_token = getAuthTokenfromcookie();
    let customer_ip = jsCookie.get('customer_ip')
    let request_guid = jsCookie.get('request_guid')
    return async (dispatch, getState) => {
        return fetch(Gconfig.fpAPI + 'Customer/RenameFavoriteBoard/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + customer_token,'X-Request-For':customer_ip, 'guid': request_guid },
            body: JSON.stringify({
                board_id: renameData.id,
                board_name: renameData.name
            })
        }).then(response => response.json()).then(data => {
            let currentData = getState().customerBoards.customerBoards
            currentData = currentData.filter(board => {
                if(renameData.id == board.board_id){
                    return board.board_name = renameData.name
                } else {
                    return board
                }
            })
            dispatch(fetchCustomerBoardsSuccess(currentData))
            return data
        })
    }
}

let getFavoriteBoardsByProduct = (product_id) => {
    let customer_token = getAuthTokenfromcookie();
    let customer_ip = jsCookie.get('customer_ip')
    let request_guid = jsCookie.get('request_guid')
    return fetch(Gconfig.fpAPI + 'Customer/GetFavoriteBoardsByProduct/' + product_id, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + customer_token,'X-Request-For':customer_ip, 'guid': request_guid }
    }).then(response => response.json()).then(data => {
        return data
    })
}
export {
    addRemoveFavoriteItem, addFavoriteBoard, getFavoriteBoardsList, addOrRemoveFavoriteBoardItem, getFavoriteItems, getFavoriteBoardItems,
    removeFavoriteBoard, renameFavoriteBoard, getFavoriteBoardsByProduct
}