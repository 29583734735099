import React from 'react';
// import ReactDOM from 'react-dom';
import { Component } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Input, Spinner } from 'reactstrap';
import { addFavoriteBoard } from 'components/shared/productFavFunctions';
import { connect } from 'react-redux';
import { noSpecialCharactersButSpace } from './globalFunctions';

class CreateBoardPopup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            board_name: '',
            loading: false,
            error: ''
        }
        this.nameInput = React.createRef()
    }

    handleChange = (e) => {
        if(e.target.value.length < 20){
            this.setState({
                [e.target.name]: noSpecialCharactersButSpace(e.target.value)
            })
        }
    }

    sendData = async () => {
        if (this.state.board_name.trim() == '') {
            document.getElementById('board_name').style.borderColor = 'red'
            setTimeout(() => {
                if(document.getElementById('board_name')){
                    document.getElementById('board_name').removeAttribute('style')
                }
            }, 2000)
            return
        }
        let exists = false
        this.props.customerBoards.filter(board => {
            if(board.board_name.toLowerCase() == this.state.board_name.toLowerCase()){
                exists = true
            }
        })
        if(exists){
            this.setState({ error: "This Board name already exists." })
            document.getElementById('board_name').style.borderColor = 'red'
            setTimeout(() => {
                if(document.getElementById('board_name')){
                    document.getElementById('board_name').removeAttribute('style')
                }
                this.setState({ error: '' })
            }, 2000)
            return
        }
        this.setState({ loading: !this.state.loading })
        let result = {}
        if (this.props.fromCollection) {
            result = await this.props._addFavoriteBoard(this.state.board_name.trim(), '', this.props.fromCollection, this.props.isFavorite)
        } else {
            result = await this.props._addFavoriteBoard(this.state.board_name.trim(), '')
        }
        if (result.success) {
            if (this.props.addBoard) {
                this.props.addBoard(result.favorite_board)
            }
            this.setState({ board_name: '', loading: !this.state.loading })
            this.props.toggle()
            document.body.style.overflow = "auto"
        } else {
            this.setState({ error: result.message, loading: !this.state.loading })
            document.getElementById('board_name').style.borderColor = 'red'
            setTimeout(() => {
                if (document.getElementById('board_name')) { document.getElementById('board_name').removeAttribute('style') }
                this.setState({ error: '' })
            }, 2000)
        }
    }

    render() {
        if(this.props.isOpen){
            setTimeout(() => {
                if(this.nameInput.current){
                    this.nameInput.current.focus()
                }
            },500)
        }
        return (
            <div>
                {/* <Button onClick={this.toggle} className="create-board">Create a Board</Button> */}
                <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} size='md' aria-labelledby="contained-modal-title-vcenter" centered animation="false" className="boardModal">
                    <ModalHeader>Create a new board</ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <input type="text" ref={this.nameInput} name="board_name" id="board_name" placeholder="Enter your board name here" onChange={this.handleChange} value={this.state.board_name} />
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <div>
                            <p style={{ color: 'red', textAlign: 'center' }} className="mb-1">{this.state.error}</p>
                            <Button className="button btn-close mr-1" onClick={this.props.toggle}>Cancel</Button>
                            <Button className="button btn-add" disabled={this.state.loading && true} onClick={this.sendData}>{this.state.loading != true ? 'Add Board' : <Spinner />}</Button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        customerBoards: state.customerBoards.customerBoards
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        _addFavoriteBoard: (boardsName, boardDes,fromCollection,isfav) => dispatch(addFavoriteBoard(boardsName, boardDes,fromCollection,isfav))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateBoardPopup)
// export default CreateBoardPopup;